import React from "react"
import { DeliveryRadioButtons } from "@common"

const Delivery = ({ cart, setShipping }) => {
  const { shippings, shipping } = cart
  return (
    <div className="delivery">
      <h5>Sposób dostawy </h5>
      <DeliveryRadioButtons
        items={shippings}
        itemMapper={el => ({
          name: "shipping",
          onChange: () => {
            setShipping(el)
          },
          value: el,
          checked: el == shipping,
          name: `${el.method_title} - ${el.total} PLN`,
        })}
      />
    </div>
  )
}

export default Delivery
