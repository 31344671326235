import React, { useRef } from "react"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Layout, Head, Stepper, Mandala, Breadcrumbs } from "@common"
import { NewsletterSection } from "@sections"

import { customerActions, cartActions } from "@stores"
import {
  CustomerForm,
  ProductsSummary,
  Delivery,
  DiscountAndContinue,
  ErrorMessage,
  OrderSummary,
  createOrder,
  initPayment,
} from "@pages/Checkout"

import "./styles/checkout.scss"

const Checkout = props => {
  const stepper = useRef({ setIndex: () => {} })

  const {
    customer,
    changeValue,
    cart,
    resetCart,
    setShipping,
    onError,
    setCoupon,
    setOrder,
    changeProductQty,
    removeFromCart,
    pageContext,
  } = props
  const { page } = pageContext
  const { orderInApi, imBusy } = cart

  if (cart.items.length === 0) {
    return (
      <Layout title={"Zamówienie"} imBusy={imBusy}>
        <Head title="Podsumowanie zakupów" />
        <div className="checkout">
          <div className="checkout-content">
            <div className="container">
              <Breadcrumbs
                elements={[
                  {
                    label: "Skarby",
                    url: "/categories/",
                  },
                  {
                    label: page.title,
                    url: `/${page.slug}/`,
                  },
                ]}
              />
              <h1>Brak rzeczy w koszyku</h1>
            </div>
          </div>
          <Mandala height={250}>
            <div className="container magictime swap">
              <div className="row justify-content-center">
                <Link className="button" to={`/categories/`}>
                  Wróć do sklepu
                </Link>
              </div>
            </div>
          </Mandala>
          <NewsletterSection />
        </div>
      </Layout>
    )
  }

  if (orderInApi.id) {
    return (
      <Layout title={"Zamówienie"} imBusy={imBusy}>
        <Head
          seo={pageContext.page.seo}
          siteMetaData={pageContext.page.siteMetaData}
        />
        <div className="checkout">
          <div className="checkout-content">
            <div className="container">
              <div className="col-sm-12">
                <ErrorMessage cart={cart} />
                <OrderSummary
                  cart={cart}
                  onInitPayment={() => {
                    initPayment(props)
                      .then(payment => {
                        window.location.href = payment.redirectUri
                      })
                      .catch(({ data }) => {
                        onError(data)
                      })
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <Layout title={"Zamówienie"} imBusy={imBusy}>
      <Head title="Podsumowanie zakupów" />
      <div className="checkout">
        <div className="checkout-content">
          <div className="row justify-content-center">
            <div className="col-md-8 col-12">
              <div className="container">
                <Stepper ref={stepper} withScroll={true}>
                  <div className="col-12" title={"1. Podsumowanie"}>
                    <ProductsSummary
                      cart={cart}
                      changeProductQty={changeProductQty}
                      removeFromCart={removeFromCart}
                    />
                    <ErrorMessage cart={cart} />
                    <DiscountAndContinue
                      items={cart.items}
                      sum={cart.sum}
                      shipping={cart.shipping}
                      nextStep={() => {
                        stepper.current.setIndex(1)
                      }}
                      coupon={cart.coupon}
                      coupons={cart.coupons}
                      onCouponChange={setCoupon}
                    />
                  </div>
                  <div className="col-12" title={"2. Wypełnij dane"}>
                    <CustomerForm
                      store={customer}
                      changeValue={changeValue}
                      cart={cart}
                    />
                    <Delivery cart={cart} setShipping={setShipping} />
                    <ErrorMessage cart={cart} />
                    <DiscountAndContinue
                      sum={cart.sum}
                      items={cart.items}
                      shipping={cart.shipping}
                      coupon={cart.coupon}
                      coupons={cart.coupons}
                      nextStep={() => {
                        createOrder(props)
                          .then(data => {
                            setOrder(data)
                          })
                          .catch(({ data }) => {
                            onError(data)
                          })
                      }}
                      label={"Potwierdź zamówienie"}
                      onCouponChange={setCoupon}
                    />
                  </div>
                </Stepper>
              </div>
            </div>
          </div>

          <Mandala height={250}>
            <div className="container magictime swap">
              <div className="row justify-content-center">
                <Link className="button" to={`/categories/`}>
                  Wróć do sklepu
                </Link>
              </div>
            </div>
          </Mandala>
          <NewsletterSection />
        </div>
      </div>
    </Layout>
  )
}

const mapStateToProps = state => {
  return { customer: state.Customer, cart: state.Cart }
}

const mapActionToDispatch = { ...customerActions, ...cartActions }

export default connect(mapStateToProps, mapActionToDispatch)(Checkout)
