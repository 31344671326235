import React from "react"
import { TextInput } from "@common"

const CustomerForm = ({ store, changeValue }) => {
  return (
    <div className="summary">
      <form
        className="customer-form"
        onSubmit={ev => {
          ev.preventDefault()
        }}
      >
        <div className="row">
          <div className="col-md-6 col-12">
            <h5>Dane osobowe</h5>
            <TextInput
              placeholder="Imię"
              name="firstName"
              value={store.customer.first_name}
              validate={"text"}
              onChange={ev => {
                changeValue({
                  first_name: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Nazwisko"
              name="lastName"
              value={store.customer.last_name}
              onChange={ev => {
                changeValue({
                  last_name: ev.target.value,
                })
              }}
            />
            <TextInput
              type={"email"}
              name="email"
              validate={"email"}
              placeholder="Adres email"
              value={store.customer.email}
              onChange={ev => {
                changeValue({
                  email: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Telefon"
              name="phoneNo"
              validate={"number"}
              value={store.customer.phone}
              onChange={ev => {
                changeValue({
                  phone: ev.target.value,
                })
              }}
            />
          </div>
          <div className="col-md-6 col-12">
            <h5>Dane do wysyłki</h5>
            <TextInput
              placeholder="Ulica"
              name="address"
              value={store.customer.address_1}
              onChange={ev => {
                changeValue({
                  address_1: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Miasto"
              name="city"
              value={store.customer.city}
              onChange={ev => {
                changeValue({
                  city: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Kod pocztowy"
              value={store.customer.postcode}
              name="zipCode"
              onChange={ev => {
                changeValue({
                  postcode: ev.target.value,
                })
              }}
            />
            <TextInput
              placeholder="Kraj"
              name="country"
              value={store.customer.country}
              onChange={ev => {
                changeValue({
                  country: ev.target.value,
                })
              }}
            />
            <div className="form-group">
              <textarea
                className="form-control"
                placeholder="Informacje dodatkowe"
                name="customerNote"
                value={store.customer.note}
                onChange={ev => {
                  changeValue({
                    note: ev.target.value,
                  })
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CustomerForm
