import React from "react"

const ErrorMessage = ({ cart }) => {
  const { error } = cart
  if (error) {
    return (
      <div className="error-message">
        <h5
          dangerouslySetInnerHTML={{
            __html: error.message,
          }}
        />
      </div>
    )
  }
  return null
}

export default ErrorMessage
