import React, { useEffect, useState } from "react"
import { WPApi } from "@api"

const DiscountAndContinue = ({
  sum,
  shipping,
  nextStep,
  coupon,
  onCouponChange,
  label = "Dalej",
  items,
}) => {
  const [state, setState] = useState({ coupons: [], affiliates: [] })

  if (state.coupons.length == 0) {
    WPApi.getAllCoupons().then(data => {
      setState({
        ...state,
        coupons: data,
      })
    })
  }

  //   if (state.affiliates.length == 0) {
  // 	WPApi.getAllAffiliates().then((data) => {
  // 	  setState({
  // 		...state,
  // 		affiliates: data,
  // 	  })
  // 	})
  //   }

  let urlCoupon = localStorage.getItem("urlCoupon")

  // useEffect(() => {
  // 	if(urlCoupon){
  // 		onCouponChange(urlCoupon)
  // 	}
  // }, [urlCoupon])

  let couponAmount = 0
  let affiliate_value = null
  let couponType = null
  let couponProductsIds = null
  let excludedProductIds = null

  if (state.coupons) {
    if (coupon) {
      state.coupons.map((el, k) => {
        if (el.code == coupon) {
          if (el.product_ids.length > 0) {
            couponProductsIds = el.product_ids
          }
          couponType = el.discount_type
          couponAmount = el.amount
          if (el.excluded_product_ids.length > 0) {
            excludedProductIds = el.excluded_product_ids
          }
        }
      })
    }
  }

  // sprawdź czy zakup jest przez kod od partnera
  let withAffiliate = false
  if (affiliate_value) {
    state.affiliates.map((el, k) => {
      if (el.affiliate_id === parseInt(affiliate_value)) {
        withAffiliate = true
        localStorage.setItem("withAffiliate", withAffiliate)
      }
    })
  }

  // oblicz procenty
  let percentageCoupon = 0
  if (couponAmount) {
    if (couponType === "percent") {
      percentageCoupon = (couponAmount / 100).toFixed(2)
    }
  }

  let fixedCoupon = 0
  if (couponAmount) {
    if (couponType === "fixed_cart") {
      fixedCoupon = couponAmount
    }
  }

  let couponCalculated = 0
  if (percentageCoupon != 0) {
    if (couponProductsIds) {
      items.forEach(el => {
        if (couponProductsIds.includes(el.product_id)) {
          couponCalculated += el.value * percentageCoupon
        }
      })
    }
    if (excludedProductIds) {
      items.forEach(el => {
        if (excludedProductIds.includes(el.product_id) === false) {
          couponCalculated += el.value * percentageCoupon
        }
      })
    } else {
      couponCalculated = sum.val * percentageCoupon
    }
  }
  if (fixedCoupon != 0) {
    couponCalculated = fixedCoupon
  }

  //aaa
  return (
    <div className="summary-bottom">
      <div className="discount">
        <input
          className="border-input"
          type="text"
          placeholder="Mam kupon rabatowy"
          onChange={ev => {
            onCouponChange(ev.target.value)
          }}
          value={coupon}
        />
      </div>
      <div className="sum">
        <h5>
          Razem:{" "}
          <span className="price">
            {typeof shipping.total === "number"
              ? sum.val + shipping.total
              : sum.val}{" "}
            PLN
          </span>
        </h5>
        {couponCalculated != 0 ? (
          <h6>Wartość kuponu: {couponCalculated} PLN</h6>
        ) : (
          <h6>&nbsp;</h6>
        )}
        <button onClick={nextStep} className="button">
          {label}
        </button>
      </div>
    </div>
  )
}

export default DiscountAndContinue
